var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("notifications", {
    staticClass: "min-w-128",
    attrs: { position: "top center" },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function(props) {
            return [
              _c(
                "div",
                {
                  staticClass: "notification",
                  class: _vm.getClass(props.item.type)
                },
                [
                  _c("svg-icon", {
                    staticClass: "icon-md mr-3 mt-1",
                    class: _vm.getColorClass(props.item.type),
                    attrs: { name: _vm.getIcon(props.item.type) }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      props.item.text
                        ? _c("span", [
                            _vm._v(" " + _vm._s(props.item.text) + " ")
                          ])
                        : _vm._e(),
                      _vm._t("default")
                    ],
                    2
                  )
                ],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }