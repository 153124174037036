var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-master" }, [
    _c("div", { staticClass: "app-content" }, [
      _c("header", { staticClass: "py-4 text-white bg-black w-full fixed" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "flex justify-between" },
            [
              _c(
                "router-link",
                {
                  staticClass: "text-lg font-semibold no-underline",
                  attrs: { to: "/" }
                },
                [
                  _c("img", {
                    staticClass: "app-logo",
                    attrs: { src: _vm.logoPath, alt: "logo" }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "flex items-center text-white no-underline hover:underline hover:text-white",
                      attrs: { to: { name: "registration" } }
                    },
                    [
                      _c("svg-icon", {
                        staticClass:
                          "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                        attrs: { name: "register" }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("zelten.registration")) + " ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isLoggedIn
                ? _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "flex items-center text-white no-underline hover:underline hover:text-white",
                          attrs: { to: { name: "profile" } }
                        },
                        [
                          _c("svg-icon", {
                            staticClass:
                              "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                            attrs: { name: "settings-user" }
                          }),
                          _vm._v(" " + _vm._s(_vm.getName) + " ")
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "flex items-center ml-8 hover:underline",
                          on: { click: _vm.logout }
                        },
                        [
                          _c("svg-icon", {
                            staticClass:
                              "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                            attrs: { name: "logout-2" }
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("general.logout")) + " ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c("main", [_vm._t("default")], 2)
    ]),
    _c(
      "footer",
      { staticClass: "py-4 text-center text-white bg-black app-footer" },
      [
        _c("div", { staticClass: "container" }, [
          _c("p", {
            staticClass: "text-lg font-semibold",
            domProps: { innerHTML: _vm._s(_vm.$t("general.footerText")) }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }