import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  // TODO: make this mote dynamic and add in setup
  base: process.env.BASE_URL || 'http://forge.docker.test',
  routes,
});

export default router;
